import { useContext } from 'react'
import AppContext from '../context/app'
import { BASE_COUNTRY_ISO } from '../../gatsby-node/constants'

export default function useGetPrice(prices) {
    const { country } = useContext(AppContext)

    const price = prices?.find(_ => _.iso === country?.node?.country_iso_code)
    const baseprice = prices?.find(_ => _.iso === BASE_COUNTRY_ISO)
    
    return {
        price: price,
        baseprice: baseprice
    }
}
