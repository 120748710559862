const ls = require('local-storage')
const { BASE_COUNTRY_ISO } = require('./gatsby-node/constants')

exports.onInitialClientRender = () => {
    // DOCUMENTATION
    // https://dev.maxmind.com/geoip/geolocate-an-ip/client-side-javascript

    function onSuccess(response) {
        const event = new CustomEvent('MAXMIND_GEO_IP', { detail: JSON.stringify(response) })
        window.dispatchEvent(event)
    }
    
    function onError(error) {
        console.error(error)
        const response = { country: { iso_code: BASE_COUNTRY_ISO } }
        const event = new CustomEvent('MAXMIND_GEO_IP', { detail: JSON.stringify(response) })
        window.dispatchEvent(event)
    }

    // This parameter is reserved for future use. 
    // There are no options at this time.
    const options = {}

    const iso = ls.get('SELECTED_COUNTRY_ISO')

    /**
     * use local storage iso if available
     * to cut down on requests to maxmind
     * 
     * if maxmind geoip2 is not available
     * that means we're running inside of builder.io
     * therefore set default iso
     */
    if (iso) {
        onSuccess({ country: { iso_code: iso } })
    } else if (window.geoip2) {
        window.geoip2.insights(onSuccess, onError, options)
    } else {
        onSuccess({ country: { iso_code: BASE_COUNTRY_ISO } })
    }
}

exports.onRouteUpdate = ({ location }) => {
    window.dispatchEvent(new Event('NAVIGATE'))

    const { hash } = location
    const el = hash ? document.querySelector(hash) : null
    if (el) window.requestAnimationFrame(() => window.scrollTo(0, el.offsetTop))
}

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition, }) => {
    const savedScrollPosition = getSavedScrollPosition(location)
    window.requestAnimationFrame(() => window.scrollTo(...savedScrollPosition))
    return false
}
