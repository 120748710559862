// FRAMEWORK
import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'

// APP
import CountrySelector from './country-selector'
import useGetAllCategory from '../hooks/useGetAllCategory'
import AppContext from '../context/app'
import Socials from './socials'

// BOOTSTRAP
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'

// SVGS
import closeIconWhite from '../images/svgs/x-close-icon-white.svg'
import newTabIconWhite from '../images/svgs/new-tab-icon-white.svg'
import chevrondowniconwhite from '../images/svgs/chevron-down-icon-white.svg'

export default function HamburgerOffcanvasNav() {
    const categories = useGetAllCategory()
    const { showNav, setShowNav } = useContext(AppContext)
    const [showSupport, setShowSupport] = useState(false)
    const [showCompany, setShowCompany] = useState(false)
    const close = () => setShowNav(false)

    return (
        <Navbar.Offcanvas
            placement="end"
            show={showNav}
            onHide={close}
            style={{
                background: `rgba(0, 0, 0, 0.9)`,
                boxShadow: `0px 0px 100px 100px rgba(0,0,0,.9)`,
            }}
        >
            <Offcanvas.Header onHide={close} className='justify-content-between justify-content-md-end align-items-center'>
                <Button variant='' onClick={close}>
                    <img src={closeIconWhite} alt='' />
                </Button>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav>
                    <p className='text-secondary ff-primary mb-0 mt-4'>SOFTWARE</p>
                    <Nav.Link
                        as={Link}
                        to='/bfd-player'
                        className='ff-primary text-uppercase text-white fw-bold'
                        style={{ fontSize: 22 }}
                    >
                        BFD PLAYER
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to='/bfd3'
                        className='ff-primary text-uppercase text-white fw-bold'
                        style={{ fontSize: 22 }}
                    >
                        BFD3
                    </Nav.Link>

                    <p className='text-secondary ff-primary mb-0 mt-4'>EXPANSIONS</p>
                    <Nav.Link
                        as={Link}
                        to='/bfd-player-expansions'
                        className='ff-primary text-uppercase text-white fw-bold'
                        style={{ fontSize: 22 }}
                    >
                        BFD PLAYER EXPANSIONS
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to='/bfd3-expansions'
                        className='ff-primary text-uppercase text-white fw-bold'
                        style={{ fontSize: 22 }}
                    >
                        BFD3 EXPANSIONS
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to='/bfd3-groove-packs'
                        className='ff-primary text-uppercase text-white fw-bold'
                        style={{ fontSize: 22 }}
                    >
                        BFD3 GROOVE PACKS
                    </Nav.Link>

                    <p className='text-secondary ff-primary mb-0 mt-4'>OFFERS</p>
                    <Nav.Link
                        as={Link}
                        to='/sale'
                        className='ff-primary text-uppercase text-white fw-bold'
                        style={{ fontSize: 22 }}
                    >
                        Sale
                    </Nav.Link>

                    <p className='text-secondary ff-primary mb-0 mt-4'>LINKS</p>
                    <a
                        href="https://profile.inmusicbrands.com/"
                        target="_blank"
                        rel='noreferrer'
                        style={{ fontSize: 22 }}
                        className='nav-link ff-primary text-uppercase text-white fw-bold d-flex align-items-center gap-2'
                    >
                        My Account
                        <img src={newTabIconWhite} alt='' />
                    </a>

                    <Dropdown onToggle={() => setShowSupport(!showSupport)} show={showSupport}>
                        <Button
                            variant=''
                            className='ff-primary text-white py-0 px-0 fw-bold'
                            onClick={() => setShowSupport(!showSupport)}
                            style={{ fontSize: 22 }}
                        >
                            SUPPORT
                        </Button>
                        <Dropdown.Toggle split variant='' className='ff-primary text-uppercase text-white border-0' style={{fontSize: 22}}>
                            <img src={chevrondowniconwhite} alt='' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-black rounded-0 border-0 p-0 m-0'>
                            <Dropdown.Item href="https://forum.bfddrums.com/" className='px-0' rel='noreferrer' target='_blank'>
                                FORUM
                            </Dropdown.Item>
                            <Dropdown.Item to="/downloads" as={Link} className='px-0'>
                                DOWNLOADS
                            </Dropdown.Item>
                            <Dropdown.Item href='https://support.bfddrums.com/en/support/home' className='px-0' rel='noreferrer' target='_blank'>
                                KNOWLEDGE BASE
                            </Dropdown.Item>
                            <Dropdown.Item href='https://support.bfddrums.com/en/support/home' className='px-0' rel='noreferrer' target='_blank'>
                                CONTACT SUPPORT
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown onToggle={() => setShowCompany(!showCompany)} show={showCompany}>
                        <Button
                            variant=''
                            className='ff-primary text-white py-0 px-0 fw-bold'
                            onClick={() => setShowCompany(!showCompany)}
                            style={{ fontSize: 22 }}
                        >
                            COMPANY
                        </Button>
                        <Dropdown.Toggle split variant='' className='ff-primary text-uppercase text-white border-0' style={{fontSize: 22}}>
                            <img src={chevrondowniconwhite} alt='' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='bg-black rounded-0 border-0 p-0 m-0'>
                            <Dropdown.Item as={Link} to='/about-us' className='px-0'>
                                ABOUT US
                            </Dropdown.Item>
                            <Dropdown.Item href='https://www.inmusicbrands.com/careers/' rel='noreferrer' target='_blank' className='px-0'>
                                CAREERS
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <CountrySelector />
                    <Socials className='my-4' />
                </Nav>
            </Offcanvas.Body>
        </Navbar.Offcanvas>
    )
}
