// FRAMEWORK
import React, { useContext } from 'react'
import { Link } from 'gatsby'

// BOOTSTRAP
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Stack from 'react-bootstrap/Stack'

// APP
import Bag from './bag'
import Cart from './cart'
import Nav from './nav'
import NavSearch from '../algolia/nav-search'
import AppContext from '../context/app'
import BrandLogo from './brand-logo'

// SVGS
import menu from '../images/svgs/toggler-icon-white.svg'

export default function Naviagtion() {
    const { setShowNav } = useContext(AppContext)

    return (
        <Navbar sticky='top' expand={false} style={{ background: 'black', height: 54 }}>
            <Container fluid>
                <Container className='position-relative d-flex align-items-center justify-content-md-between justify-content-end'>
                    <NavSearch className='d-none d-md-inline' />
                    
                    <Navbar.Brand className='position-absolute top-50 start-50 translate-middle p-0 m-0'>
                        <BrandLogo />
                    </Navbar.Brand>
                    
                    <Stack direction='horizontal' className='align-items-center' gap={4}>
                        <Bag />
                        <Navbar.Toggle onClick={() => setShowNav(true)} className='p-0'>
                            <img src={menu} alt='' />
                        </Navbar.Toggle>
                    </Stack>

                    <Nav />
                    <Cart />
                </Container>
            </Container>
        </Navbar>
    )
}
