import { useStaticQuery, graphql } from 'gatsby'

export default function useGetAllCategory() {
    const data = useStaticQuery(graphql`
        query {
            allCategory(sort: { name: ASC }){
                edges {
                    node {
                        id
                        name
                        description
                        slug
                        is_featured
                        categoryId
                        background_color
                        thumbnailField {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        heroImageField {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    `)
    return data.allCategory.edges
}
