import { PIMCORE_URL, ENDPOINTS } from "../../gatsby-node/constants"

export default async function rollbackVoucher(session_cart_id, iso) {
    const options = {
        method: 'post',
        body: JSON.stringify({ session_cart_id })
    }

    const r = await fetch(`${PIMCORE_URL}${ENDPOINTS.ROLLBACK_VOUCHER(iso)}`, options)
    const d = await r.json()

    const { success, message } = d
    if (!success) console.error(message)
}
