import React from 'react'
import { Builder } from '@builder.io/react'
import useGetProduct from '../../hooks/useGetProduct'
import useGetPrice from '../../hooks/useGetPrice'
import ESW_CLASSNAMES from '../../esw/classnames'
import image from './images/dollar.svg'

export default function DiscountedPrice({ sku }) {
    const { prices } = useGetProduct(sku) ?? {}
    const { price, baseprice } = useGetPrice(prices)
    
    return <span
        style={{marginRight: 8}}
        className={ESW_CLASSNAMES.PAGE_PRICE}
        data-bp-lti={`${baseprice?.currency_symbol}${baseprice?.promotion_price}`}
    >
        {price?.promotion_price && `${price?.currency_symbol}${price?.promotion_price}`}
    </span>
}

Builder.registerComponent(DiscountedPrice, {
    name: 'Discounted Price',
    image,
    inputs: [
        {
            name: 'sku',
            type: 'string',
            required: true,
        }
    ]
})