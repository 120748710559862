import React from 'react'
import { Link } from 'gatsby'
import logo from '../images/logo.png'

export default function BrandLogo() {
    return (
        <Link to='/' className='d-block'>
            <img src={logo} alt='' style={{ height: 40 }} />
        </Link>
    )
}