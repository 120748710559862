import { useStaticQuery, graphql } from 'gatsby'

export default function useGetAllCountry() {
    const data = useStaticQuery(graphql`
        query {
            allCountry {
                edges {
                    node {
                        country_iso_code
                        currency_iso_code
                        currency_symbol
                        name
                        flagImage {
                            childImageSharp {
                                gatsbyImageData(width: 32)
                            }
                        }
                    }
                }
            }
        }
    `)
    return data.allCountry.edges
}