import useGetAllProduct from './useGetAllProduct'

// takes pimcore id or sku
export default function useGetProduct(id) {
    const allProduct = useGetAllProduct()
    const product = allProduct.find(({ node: { productId, sku } }) => {
        if(typeof id === 'number') return id === productId
        else return id === sku
    })
    return product?.node
}
