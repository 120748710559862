import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { Builder, withChildren, BuilderBlocks } from '@builder.io/react'
import AppContext from '../../context/app'
import image from './images/vimeo.png'

export default function Vimeo(props) {
    const { children } = props
    const { screenWidth, clientWidth } = useContext(AppContext)

    class Video {
        constructor(id, width, height) {
            this.id = id
            this.height = height
            this.width = width
            this.aspectRatio = height / width
        }
    }

    const builder_mobile_video = props['Mobile Video']
    const builder_desktop_video = props['Desktop Video']
    
    const mobile = builder_mobile_video ?
        new Video(
            builder_mobile_video?.id,
            builder_mobile_video?.width,
            builder_mobile_video?.height
        )
        : null
    
    const desktop = new Video(
        builder_desktop_video?.id,
        builder_desktop_video?.width,
        builder_desktop_video?.height
    )

    const video = (mobile && screenWidth < 768) ? mobile : desktop

    return (
        <Container
            fluid
            className='position-relative'
            style={{
                paddingTop: `${(video.aspectRatio * clientWidth).toFixed(0)}px`
            }}
        >
            {/* VIMEO VIDEO EMBED */}
            <iframe
                src={`https://player.vimeo.com/video/${video.id}?muted=1&autoplay=1&loop=1&controls=0`}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                className='position-absolute w-100 h-100 start-0 top-0'
                title=""
            />
            {/* "BACKDROP" */}
            <div
                className='position-absolute w-100 h-100 start-0 top-0'
                style={{ background: `rgba(0,0,0,.5)` }}
            />
            {children && <BuilderBlocks blocks={children} />}
        </Container>
    )
}

Builder.registerComponent(withChildren(Vimeo), {
    name: 'Vimeo',
    noWrap: true,
    image,
    inputs: [
        {
            name: 'Desktop Video',
            type: 'object',
            helperText: 'Required',
            required: true,
            subFields: [
                {
                    name: 'id',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'width',
                    type: 'number',
                    required: true,
                    helperText: 'Pixels'
                },
                {
                    name: 'height',
                    type: 'number',
                    required: true,
                    helperText: 'Pixels'
                }
            ]
        },
        {
            name: 'Mobile Video',
            type: 'object',
            helperText: 'Optional',
            subFields: [
                {
                    name: 'id',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'width',
                    type: 'number',
                    required: true,
                    helperText: 'Pixels'
                },
                {
                    name: 'height',
                    type: 'number',
                    required: true,
                    helperText: 'Pixels'
                }
            ]
        },
    ]
})
