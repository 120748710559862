import React from 'react'
import Search from './search'
import Results from './results'

// https://www.algolia.com/doc/api-reference/widgets/react/
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch'

// https://www.gatsbyjs.com/docs/how-to/local-development/environment-variables/#accessing-environment-variables-in-the-browser
const algoliaClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY)

// https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/vue/
const searchClient = {
    ...algoliaClient,
    search(requests) {
        if (requests.every(({ params }) => !params.query)) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                    hitsPerPage: 0,
                    exhaustiveNbHits: false,
                    query: '',
                    params: '',
                })),
            })
        }

        return algoliaClient.search(requests);
    }
}

export default function NavSearch({ className }) {
    return (
        <div className={`${className} position-relative`}>
            {/* https://www.algolia.com/doc/api-reference/widgets/instantsearch/react/#examples */}
            <InstantSearch indexName="BFD Products" searchClient={searchClient}>
                <Configure hitsPerPage={1000} />
                <Search />
                <Results />
            </InstantSearch>
        </div>
    )
}
