import React from 'react'
import { Builder } from '@builder.io/react'
import useGetProduct from '../../hooks/useGetProduct'
import useGetPrice from '../../hooks/useGetPrice'
import ESW_CLASSNAMES from '../../esw/classnames'
import image from './images/dollar.svg'

export default function Price({ sku }) {
    const { prices } = useGetProduct(sku) ?? {}
    const { price, baseprice } = useGetPrice(prices)

    return <span
        className={`${ESW_CLASSNAMES.PAGE_PRICE} ${baseprice?.promotion_price ? 'text-decoration-line-through' : 'fw-bold'}`}
        data-bp-lti={`${baseprice?.currency_symbol}${baseprice?.price}`}
    >
        {price?.price && `${price?.currency_symbol}${price?.price}`}
    </span>
}

Builder.registerComponent(Price, {
    name: 'Original Price',
    image,
    inputs: [
        {
            name: 'sku',
            type: 'string',
            required: true,
        }
    ]
})