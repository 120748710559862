import React from 'react'
import { Builder } from '@builder.io/react'
import useGetProduct from '../../hooks/useGetProduct'
import AddToCart from '../../components/add-to-cart'
import image from './images/bag.svg'

export default function BuilderAddToCart({ sku }) {
    const product = useGetProduct(sku)
    return <AddToCart
        product={product}
        className='h-100 w-100 p-0 border-0 flex-center gap-2'
    />
}

Builder.registerComponent(BuilderAddToCart, {
    name: 'Add to Cart',
    image,
    inputs: [
        {
            name: 'sku',
            type: 'string',
            required: true,
        }
    ]
})
