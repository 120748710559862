import { useEffect } from "react"
import getCart from "../utilities/get-cart"

export default function useCartIdEffect(value) {
    const { cartId } = value

    useEffect(() => {
        (async () => {
            if (!cartId) return
            const e = { type: 'useEffect' }
            await getCart(e, value)
        })()
    }, [cartId])
}