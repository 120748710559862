import { useStaticQuery, graphql } from 'gatsby'

export default function useGetAllFile() {
    const data = useStaticQuery(graphql`
        query {
            allFile {
                edges {
                    node {
                        relativePath
                        publicURL
                    }
                }
            }
        }
    `)
    return data.allFile.edges
}
