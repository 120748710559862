import React, { useContext, useState, useEffect } from 'react'
import AppContext from '../context/app'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { PIMCORE_URL, ENDPOINTS } from '../../gatsby-node/constants'
import bag from '../images/svgs/bag-icon-black.svg'
import check from '../images/svgs/check-icon-black.svg'
import rollbackVoucher from '../utilities/rollback-voucher'
import applyVoucher from '../utilities/apply-voucher'

export default function AddToCart({ product, className, style, callBack }) {
    const { country, cartId, loadingCart, setLoadingCart, cart } = useContext(AppContext)
    const [clicked, setClicked] = useState(false)

    const id = product?.productId
    const session_cart_id = cartId
    const iso = country?.node.country_iso_code

    async function handleOnClick() {
        if (callBack) callBack()
        setClicked(true)
        setLoadingCart(true)

        const options = {
            method: 'post',
            body: JSON.stringify({ id, qty: 1, session_cart_id })
        }

        const r = await fetch(`${PIMCORE_URL}${ENDPOINTS.ADD_TO_CART(iso)}`, options)
        const { success, data, cart_id } = await r.json()

        if (success) {
            const voucher_code = cart?.voucher_code
            if (voucher_code) {
                try {
                    await rollbackVoucher(session_cart_id, iso)
                    await applyVoucher(session_cart_id, iso, voucher_code)
                } catch (err) {
                    console.error(err)
                }
            }

            if (typeof window === 'undefined') return
            const event = new Event('CART_CHANGE')
            window.dispatchEvent(event)
        } else {
            setLoadingCart(false)
            console.error(`Error adding ${product.product_name} to cart`)
        }
    }

    useEffect(() => {
        if (!loadingCart && clicked) setClicked(false)
    }, [loadingCart])

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
    const isInCart = cart?.items?.some(({ product_id }) => product_id === id)

    return (
        <Button
            variant=''
            style={style}
            className={className}
            onClick={handleOnClick}
            disabled={loadingCart || isInCart}
        >
            {
                (clicked && loadingCart) ?
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    (isInCart) ?
                        <>
                            <img src={check} alt='' height={16} />
                            IN CART
                        </>
                        :
                        <>
                            <img src={bag} alt='' />
                            ADD TO CART
                        </>
            }
        </Button>
    )
}
