import React, { useState } from 'react'
import { Link } from 'gatsby'
import Button from 'react-bootstrap/Button'
import useGetAllProduct from '../hooks/useGetAllProduct'

export default function Related({ children = '', products = [], truncate = false, className }) {
    const [toggle, setToggle] = useState(truncate)
    
    const allProduct = useGetAllProduct()
    products = products.map(({ productId }) => allProduct.find(({ node }) => productId === node.productId))
    const data = toggle ? products.slice(0, 1) : products

    return (
        <p className={`${className}`}>
            {/* TITLE - EX: "Requires: " */}
            <em>{children}</em>

            {/* PRODUCT NAME/LINK TO PDP */}
            {
                data.map(({ node }, i) => {
                    const { product_name, shop_categories, slug } = node
                    const url = shop_categories?.length > 0 ? `/${shop_categories[0].slug}/${slug}` : `/${slug}`
                    const isNotLast = (i !== data.length - 1)
                    return (
                        <>
                            <Link
                                key={`related-link-${slug}-${i}`}
                                to={url}
                                className="text-black fw-bold"
                                style={{ textDecoration: 'underline' }}
                            >
                                {product_name}
                            </Link>
                            {isNotLast && <span>, </span>}
                        </>
                    )
                })
            }

            {/* ... & 3 more" BUTTON */}
            {
                (toggle && products.length > 1) && <Button
                    variant=''
                    className='ff-secondary p-0 align-baseline'
                    onClick={() => setToggle(false)}
                    title={products.slice(1).map(({ node }) => node.product_name)}
                >
                    <strong className={className}>... & {products.length - 1} more</strong>
                </Button>
            }
        </p>
    )
}
