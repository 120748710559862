import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useGetSiteMetadata from '../hooks/useGetSiteMetadata'

import arrowright from '../images/svgs/arrow-right-icon-black.svg'
import inmusic from '../images/svgs/inmusic-logo-black.svg'

export default function InMusic() {
    const { brandName } = useGetSiteMetadata()
    return (
        <Container fluid style={{ background: '#D2D2D2' }}>
            <Container className='py-5'>
                <Row className='align-items-center mb-5'>
                    <Col xs='auto'>
                        <h2 className='fw-bold'>
                            <img src={inmusic} alt='' />
                        </h2>
                    </Col>
                    <Col>
                        <div style={{ height: 1, background: 'black' }} />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <h3 className='fw-bold'>HOME OF THE WORLD’s PREMIER MUSIC AND AUDIO TECHNOLOGY BRANDS</h3>
                    </Col>
                    <Col>
                        <p>{brandName} is a member of the premier family of brands known as inMusic committed to pushing the boundaries of innovation in the DJ, music production, live sound, musical instrument, pro audio, and consumer electronics industries, each of inMusic’s brands creates cutting-edge products that incorporate and build upon the latest in engineering, design, and technology.</p>
                        <a href="https://inmusicbrands.com" rel='noreferrer' target='_blank' className='text-uppercase text-black fw-bold'>Find OUt MORE at INMUSICBRANDS.COM <img src={arrowright} alt='' /></a>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}