const ESW_CLASSNAMES = {
    CART_PRICE: 'esw-cart-price',
    CART_ITEM: 'esw-cart-item',
    CARTITEM_QUANTITY: 'esw-cartitem-quantity',
    CARTITEM_SUBTOTAL: 'esw-cartitem-subtotal',
    CART_SUBTOTAL: 'esw-cart-subtotal',
    CART_TOTAL: 'esw-cart-total',
    PAGE_PRICE: 'esw-page-price',
    DISCOUNT_ITEM: 'esw-discount-item',
    CHECKOUT_BUTTON: 'esw-checkout-btn',
    RETURNS_BUTTON: 'esw-returns-btn',
    RETURNS_DROPDOWN: 'esw-returns-dropdown-btn',
    RETURNS_LEADING_TEXT: 'esw-returns-leading-text',
    COUNTRY_RETURNS_PORTAL: 'esw-country-returns-portal',
    COUNTRY_RETURNS_CUSTOM: 'esw-custom-country-returns',
}

module.exports = ESW_CLASSNAMES
