import React, { useContext } from 'react'
import AppContext from '../context/app'
import useGetAllProduct from '../hooks/useGetAllProduct'

export default function Subtotal() {
    const { cart, country } = useContext(AppContext)
    const allProduct = useGetAllProduct()

    function reducer(total, item) {
        const { product_id, qty, } = item
        const { node } = allProduct.find(({ node }) => node.productId === product_id)
        const { prices } = node
        const price = prices.find(({ iso }) => iso === country.node.country_iso_code)
        total += price.price * qty
        return total
    }

    return <>{country.node.currency_symbol + cart?.items.reduce(reducer, 0).toFixed(2)}</>
}
